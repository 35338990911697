import React from 'react'

export default () =>
  <section id='hours' className='section section-center section section-blog'>
    <div className='container'>
      <h2 className='section-title'><span>Hours</span></h2>
      <h4>Sunday     1 PM - 5 PM</h4>
      <h4>Monday     8 AM - 7 PM</h4>
      <h4>Tuesday    8 AM - 7 PM</h4>
      <h4>Wednesday  8 AM - 7 PM</h4>
      <h4>Thurday    8 AM - 7 PM</h4>
      <h4>Friday     8 AM - 7 PM</h4>
      <h4>Saturday   8 AM - 6:30 PM</h4>
      <br />
    </div>
  </section>
