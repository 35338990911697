import React from 'react'
import PropTypes from 'prop-types'

import FooterComponent from './Footer'

const Layout = ({children}) =>
  <div>
    <div id='page'>
      <header id='header' className='site-header'>
        <nav id='topbar' className='site-topbar visible-lg visible-md'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>Questions? Call us:<a href='tel:+18069833000'>(806) 983-3000</a></div>
              <div className='social-links col-sm-6'>
                <a href='https://www.facebook.com/Floydada-Ace-Hardware-Lumber-76989105263/'target='_blank'><i className='fa fa-facebook' /></a>
                <a href='https://www.youtube.com/watch?v=nTLK9GoUcI8'target='_blank'><i className='fa fa-youtube' /></a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {children}
      <FooterComponent />
    </div>
    <div className='scroll-to-top' data-spy='affix' data-offset-top='200'><a href='#page' className='smooth-scroll'><i className='fa fa-arrow-up' /></a></div>
  </div>

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
