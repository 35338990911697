import React from 'react'

export default () =>
  <section id='departments' className='section section section-our-clients'>
    <div className='container'>
      <h2 className='section-title'><span>Brands</span></h2>
      <div className='row departments-runner'>
        {[
          {img: require('../static/images/ace-store-photos/stihllogo.png')},
          {img: require('../static/images/ace-store-photos/valsparlogo.png')},
          {img: require('../static/images/ace-store-photos/yetilogo.png')},
          {img: require('../static/images/ace-store-photos/dewaltlogo.png')},
          {img: require('../static/images/ace-store-photos/hiprologo.png')},
          {img: require('../static/images/ace-store-photos/scottslogo.png')}
        ].map(({img}, ind) =>
          <div className={'col-lg-2'} key={'brand-item' + ind}>
            <img src={img} />
          </div>
        )}

      </div>
    </div>
    <br />
    <br />
  </section>
