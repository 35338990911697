import React from 'react'

export default () =>
  <section id='location' className='section section-center section-hilite section-team'>
    <div className='container'>
      <h2 className='section-title'><span>Location</span></h2>
      <br />
      <iframe name='gMap'
        src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.640627242123!2d-101.34520388469865!3d33.976075280626645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ffd5534b1ffa7f%3A0x53916e23ea7f1e41!2sAce+Hardware+%26+Lumber!5e0!3m2!1sen!2sus!4v1550436189219'}
        width={600} height={400} frameBorder={0} allowFullScreen />
      <br />
    </div>
  </section>
