import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import {Routes} from './routes'

import Layout from './components/Layout'
import createStore from './store'

import './static/css/template.css'
import './static/css/style.css'
import 'react-dates/lib/css/_datepicker.css'

const store = createStore(window.REDUX_DATA)

const jsx = (
  <ReduxProvider store={store}>
    <Router>
      <Layout>
        <Routes />
      </Layout>
    </Router>
  </ReduxProvider>
)

const app = document.getElementById('app')
ReactDOM.hydrate(jsx, app)
