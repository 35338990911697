import React from 'react'
import { withRouter } from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

export default withRouter((props) =>
  <Navbar
    id='navbar'
    collapseOnSelect
    className='site-navbar navbar navbar-static-top one-page-nav'>
    <Navbar.Brand
      bsPrefix='ace-logo'
    >
      <img
        src={require('../static/images/ace-store-photos/ace-hardware-eps-vector-logo.png')}
        width='128'
        height='70'
        className='d-inline-block align-top'
        alt='Ace logo'
      />
    </Navbar.Brand>
    <Navbar.Toggle />
    <Navbar.Collapse>
      <Nav
        id='navigation'
        navbar
      >
        {props.locs.map((ele, ind) =>
          <Nav.Link
            key={ind + 1}
            href={`${ele.href}`}
            active={props.location.pathname === ele.href}
          >
            {ele.name}
          </Nav.Link>
        )}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
