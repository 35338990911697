import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home.js'

const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  }
]

const Routes = () =>
  <Switch>
    {
      routes.map(route => <Route key={route.path} {...route} />)
    }
  </Switch>

export {
  Routes,
  routes
}
