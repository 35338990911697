import React from 'react'
export default () =>
  <section id='departments' className='section section-center section-hilite section-team'>
    <div className='container'>
      <h2 className='section-title'><span>Departments</span></h2>
      <div className='row departments-runner'>
        {[
          {name: 'Feed', img: require('../static/images/ace-store-photos/feed-2500x1667.jpg')},
          {name: 'Farm & Animal Health Supplies', img: require('../static/images/ace-store-photos/hay-2500x1667.jpg')},
          {name: 'Lawn & Garden', img: require('../static/images/ace-store-photos/lawn-garden-2500x1667.jpg')},
          {name: 'Paint', img: require('../static/images/ace-store-photos/paint.jpg')},
          {name: 'Building Materials', img: require('../static/images/ace-store-photos/building-materials-2500x1667.jpg')},
          {name: 'Plumbing', img: require('../static/images/ace-store-photos/plumbing-2500x1667.jpg')},
          {name: 'Welding Gases & Supples', img: require('../static/images/ace-store-photos/gases-2500x1667.jpg')},
          {name: 'Electrical Supples', img: require('../static/images/ace-store-photos/electrical-2500x1667.jpg')},
          {name: 'Metal Building Supples', img: require('../static/images/ace-store-photos/metal-building-2500x1667.jpg')},
          {name: 'Rentals', img: require('../static/images/ace-store-photos/deere-rental-2500x1667.jpg')}
        ].map(({name, img}, ind) =>
          <div className={ind === 0 ? 'col-lg-1 departments-runner-item' : 'col-lg-1 departments-runner-item'} key={'dept-item' + ind}>
            <img src={img} />
            <h5>{name}</h5>
          </div>
        )}
      </div>
      <br />
      <br />
      <h2 className='section-title'><span>Services</span></h2>
      <div className='col-md-3 col-sm-3 col-xs-12'>
        <h4>Glass cutting</h4>
        <h4>Plexiglass cut</h4>
      </div>
      <div className='col-md-3 col-sm-3 col-xs-12'>
        <h4>Screen repair</h4>
        <h4>Key cutting</h4>
      </div>
      <div className='col-md-3 col-sm-3 col-xs-12'>
        <h4>Key master locks</h4>
        <h4>Automotive Transponder Security Keys</h4>
      </div>
      <div className='col-md-3 col-sm-3 col-xs-12'>
        <h4>Stihl Power Tool Repair</h4>
        <h4>Rentals</h4>
      </div>
    </div>
  </section>
