import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
export default () =>
  <Carousel>
    <Carousel.Item className='test'>
      <img alt='900x500' src={require('../static/images/ace-store-photos/store_front_2500x1667.jpg')} />
    </Carousel.Item>
    <Carousel.Item className='test'>
      <img width={900} height={500} alt='900x500' src={require('../static/images/ace-store-photos/feed-2500x1667.jpg')} />
      <Carousel.Caption>
        <h3>Feed</h3>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className='test'>
      <img width={900} height={200} alt='900x500' src={require('../static/images/ace-store-photos/plumbing-2500x1667.jpg')} />
      <Carousel.Caption>
        <h3>Plumbing & Building Materials</h3>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className='test'>
      <img width={900} height={500} alt='900x500' src={require('../static/images/ace-store-photos/deere-rental-2500x1667.jpg')} />
      <Carousel.Caption>
        <h3>Rentals</h3>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className='test'>
      <img width={900} height={500} alt='900x500' src={require('../static/images/ace-store-photos/gases-2500x1667.jpg')} />
      <Carousel.Caption>
        <h3>Many more departments</h3>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
