import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import Container from 'react-bootstrap/Container'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
// import 'react-dates/lib/css/_datepicker.css'

let equipmentOptions = [
  {value: 'Tiller', label: 'Tiller'},
  {value: 'Car Hauler Trailer', label: 'Car Hauler Trailer'},
  {value: 'Portable Post Hole Auger - Stihl BT 131', label: 'Portable Post Hole Auger - Stihl BT 131'},
  {value: 'Walk Behind Trencher - Ditch Witch RT24', label: 'Walk Behind Trencher - Ditch Witch RT24'},
  {value: 'Skid Steer - John Deere 324E', label: 'Skid Steer - John Deere 324E'},
  {value: 'Bucket', label: 'Bucket'},
  {value: 'Forks', label: 'Forks'},
  {value: '9” Auger', label: '9” Auger'},
  {value: '12” Auger', label: '12” Auger'},
  {value: 'Carpet Shampooer', label: 'Carpet Shampooer'},
  {value: 'Pex Fitting Crimper', label: 'Pex Fitting Crimper'}
]

let validated = true

class EquipementForm extends Component {
  handleSubmit () {

  }
  render () {
    return (
      <section id='departments' className='section section-center section-hilite section-team'>
        <h2 className='section-title'><span>Rental Form</span></h2>
        <h4><span>Please submit the following information and we will contact you to confirm pricing and availability.</span></h4>
        <h6>If you prefer to reach us by phone, please contact us at<a href='tel:+18069833000'> (806) 983-3000</a></h6>
        <Container>

          <Row>
            <Col md={{offset: 4, span: 4}}>
              <Form
                validated={false}
                onSubmit={e => this.handleSubmit(e)}>
                <Row>
                  <DateRangePicker as={Col} md='6' controlId='validationCustom01'
                    // startDate={} // momentPropTypes.momentObj or null,
                    // startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    // endDate={} // momentPropTypes.momentObj or null,
                    // endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    // onDatesChange={({ startDate, endDate }) => {})} // PropTypes.func.isRequired,
                    // focusedInput={true} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    // onFocusChange={focusedInput => {}} // PropTypes.func.isRequired,
                  />
                </Row>
                <Form.Row as={Row}>
                  <Select
                    isMulti
                    defaultValue='Choose one of the following...'
                    name='equipment'
                    options={equipmentOptions}
                  />
                </Form.Row>
                <Form.Group as={Row}>
                  <Form.Control
                    type='text'
                    placeholder='First name'
                  />
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Control
                    type='text'
                    placeholder='Last name'
                  />
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Control size='lg' placeholder='Email Address' />
                </Form.Group>
                <Form.Group as={Row} controlId='formPhoneNumber'>
                  <Form.Control size='lg' type='text' placeholder='Phone Number' />
                  <Form.Control.Feedback type='invalid'>
                    Please provide a valid phone number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Control size='lg' as='textarea' placeholder='Comments' rows='3' />
                </Form.Group>
                <Button size='lg' >
              Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>)
  }
}

export default EquipementForm
