import React from 'react'

export default () =>
  <footer id='footer' className='site-footer'>
    <div className='container'>

      <div id='contact-us'>
        <div className='row'>
          <br />
          <aside className='widget col-md-3 col-sm-6'>
            <h5 className='widget-title' />
            <p />
          </aside>
          <br />
          <aside className='widget col-md-3 col-sm-6'>
            <h3 className='widget-title'>Contact</h3>
            <div className='textwidget'>
              <div className='adr'>
                <div className='street-address'><a href='https://www.google.com/maps/place/Ace+Hardware+%26+Lumber/@33.9760753,-101.3430152,15z/data=!4m5!3m4!1s0x0:0x53916e23ea7f1e41!8m2!3d33.9760753!4d-101.3430152'target='_blank'>1101 S. Ralls Hwy</a></div>
                <span className='locality'>Floydada</span>,
                <abbr className='region'> Texas</abbr>,
                <span className='postal-code'> 79235</span>,
                <span className='country-name'> U.S.A</span>
              </div>
              <div>Phone:<a href='tel:+18069833000'> (806) 983-3000</a></div>
              <div>Fax: <span className='tel'>(806) 983-6000</span></div>
            </div>
          </aside>

          <aside className='widget col-md-3 col-sm-6'>
            <ul className='social-links'>
              <li><a href='https://www.facebook.com/Floydada-Ace-Hardware-Lumber-76989105263/'target='_blank'><i className='fa fa-facebook' /></a></li>
              <li><a href='https://www.youtube.com/watch?v=nTLK9GoUcI8'target='_blank'><i className='fa fa-youtube' /></a></li>
            </ul>
          </aside>

        </div>
      </div>
      <br />
      <br />

      <div className='copyright'><p>© {'2019'} Floydada Ace Hardware.</p></div>
      <br />

    </div>
  </footer>
