import React from 'react'

import Navbar from '../components/Navbar'
import Brands from '../components/Brands'
import Departments from '../components/Departments'
import Carousel from '../components/Carousel'
import Location from '../components/Location'
import Hours from '../components/Hours'
import EquipmentForm from '../components/EquipmentForm'

const locs = [
  {name: 'Departments', href: '/#departments'},
  {name: 'Hours & Location', href: '/#location'},
  {name: 'Contact Us', href: '/#contact-us'}
]

const Index = () =>
  <main id='main' className='site-main'>
    <Navbar locs={locs} />
    <Carousel />
    <section className='section section-center section-cta'>
      <div className='container'>
        <div className='main-action row'>
          <div className='col-md-3 col-md-offset-3 col-sm-4 col-sm-offset-2'><a href='#hours' className='smooth-scroll btn btn-lg btn-block btn-danger'>Our Hours</a></div>
          <div className='col-md-3 col-sm-4'><a href='#location' className='smooth-scroll btn btn-lg btn-block btn-default'>Our Location</a></div>
        </div>
      </div>
    </section>
    <Departments />
    <Brands />
    { false && <EquipmentForm /> }
    <Location />
    <Hours />
  </main>

export default Index
